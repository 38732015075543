// Dependencies
import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs';
import ReactHtmlParser from 'react-html-parser';
// Actions
import { iniciarSesion } from '../../../actions/userAction';

// Assets
import './css/css.css';

import Footer from '../../../components/Global/Footer/Footer';
import Boton from '../../../components/Inputs/Boton';
import Input2 from '../../../components/Inputs/Input2';
import swal from 'sweetalert';

class PreparacionCursos extends Component {
  static propTypes = {
    iniciarSesion: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string,
    tipoUser: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      viewPrepaHome: 1,
      viewPrepaHome2: 0,
      preparacion: {
        locaActual: 'inicio',
        modePrepa: 'normal',
        tipoPreparacion: null,
        inicio: [
          // {
          //   tipoPreparacion: 'Cursos_Online',
          //   titulo1: 'Cursos Online',
          //   titulo2: '',
          //   infoText: 'Para opositores que quieren <b>prepararse 100% online</b>.<br /><br />Mediante nuestros cursos intensivos y ordinarios. Financiable al 0% de interés.',
          //   precioDesde: '45'
          // },
          {
            tipoPreparacion: 'Presencial',
            titulo1: 'Presencial',
            titulo2: 'Málaga Teatinos',
            infoText: 'Para opositores que quieren <b>prepararse Presencialmente</b> en Málaga-Teatinos.<br /><br />Con preparador personal y campus virtual especialido en oposiciones a la administración de Justicia.',
            precioDesde: '110'
          },
          {
            tipoPreparacion: 'Online_Video_Presencial',
            titulo1: 'Online',
            titulo2: 'Por Videoconferencia',
            infoText: 'Para opositores que quieren <b>prepararse a distancia</b>, con la misma calidad y ventajas que en clases presenciales.<br /><br />Mediante clases por video conferencia, con prepador personal.',
            precioDesde: '90'
          }
        ],
        misCursosRepasos: []
      }
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }


  async onHandleClick(e) {
    if (e.target.id.split('-')[0] == 'openTipoPreparacion') {

      let idLoca = e.target.id.split('-')[1]
      let tipoPreparacion = this.state.preparacion.inicio[idLoca].tipoPreparacion
      this.props.history.push('/Preparacion/' + tipoPreparacion);
    } else if (e.target.id.split('-')[0] == 'openTipoRepaso') {

      let idLoca = e.target.id.split('-')[1]
      let tipoPreparacion = this.state.preparacion.misCursosRepasos[idLoca].tipoPreparacion
      let nombreOpo = this.state.preparacion.misCursosRepasos[idLoca].nombreOposicion
      let idClase = this.state.preparacion.misCursosRepasos[idLoca].idClase
      let opoIS = nombreOpo == 'Auxilio y Tramitacion' ? ('Aux-Tra-libre') :
        (nombreOpo == 'Gestion' ? ('Gestion-libre') : (
          nombreOpo == 'Tramitacion PI' ? ('Tramitacion-interna') : (
            nombreOpo == 'Gestion PI' ? ('Gestion-interna') : null
          )
        ))

      this.props.history.push('/Preparacion/Repaso/' + tipoPreparacion + '/' + opoIS + '/' + idClase);

    } else if (e.target.id == 'atras') {
      this.props.history.push('');
    } else if (e.target.id == 'help') {
      swal('Si necesita ayuda', 'Puede llamarnos al: 688 98 80 99', 'info')
    } else if (e.target.id == 'left') {
      let dato = this.state.viewPrepaHome
      if (dato > 0) {
        this.setState({ viewPrepaHome: dato - 1 })
      }

    } else if (e.target.id == 'right') {
      let dato = this.state.viewPrepaHome + 1
      if (dato < this.state.preparacion.inicio.length) {
        this.setState({ viewPrepaHome: dato })
      }
    } else if (e.target.id == 'left2') {
      let dato = this.state.viewPrepaHome2
      if (dato > 0) {
        this.setState({ viewPrepaHome2: dato - 1 })
      }

    } else if (e.target.id == 'right2') {
      let dato = this.state.viewPrepaHome2 + 1
      if (dato < this.state.preparacion.misCursosRepasos.length) {
        this.setState({ viewPrepaHome2: dato })
      }
    } else if (e.target.id == 'goNormal') {
      let dato = this.state.preparacion
      dato.modePrepa = 'normal'
      this.setState({ preparacion: dato })
    } else if (e.target.id == 'goRepaso') {
      let dato = this.state.preparacion
      dato.modePrepa = 'repaso'
      this.setState({ preparacion: dato })
    }



  }
  onChangeInput(e) {

    if (e.target.name == 'dniInput') {
      this.setState({ checkedDni: undefined })
      this.setState({ dniUser: e.target.value });
    } else if (e.target.name == "passwordInput") {
      this.setState({ checkedPass: undefined })
      this.setState({ passwordUserInput: e.target.value });
    }

  }
  async componentDidMount() {

    // if(window.location.href.split('https://www.').length > 1){
    //   window.location.href = 'https://oposiciones-justicia.es/'
    // } <Redirect to ="devCEJ/escritorio" /> cambiarlo parra la app live! ************

    // cargar misCursosRepasos 
    if (this.props.isLogged) {


      let data = { idUser: this.props.idUser }
      let response = await fetch("https://oposiciones-justicia.es/api/usuarios/infoClasesAlumno", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

      let preparacionS = this.state.preparacion
      for (let i = 0; i < cls.length; i++) {
        preparacionS.misCursosRepasos.push({

          tipoPreparacion: cls[i].tipoClase == 'CURSO ONLINE' ? ('Cursos_Online') : (cls[i].tipoClase == 'ONLINE' ? 'Online_Video_Presencial' : 'Presencial'),
          titulo1: cls[i].tipoClase == 'CURSO ONLINE' ? ('Curso Online') : (cls[i].tipoClase == 'ONLINE' ? 'Online por videoconferencia' : 'Presencial'),
          titulo2: cls[i].nombreOposicion,
          infoText: 'Accede a todo el contenido que ya has visto, además todos los temas actualizados y notificaciones de las reformas de ley.',
          precioDesde: cls[i].tipoClase == 'CURSO ONLINE' ? ('20') : (cls[i].tipoClase == 'ONLINE' ? '25' : '25'),
          nombreCurso: cls[i].nombreCurso,
          tipoClase: cls[i].tipoClase,
          idClase: cls[i].idClase,
          idOposicion: cls[i].idOposicion,
          nombreOposicion: cls[i].nombreOposicion

        })
      }

      this.setState({ preparacion: preparacionS })
    }
  }





  render() {
    let { preparacion, viewPrepaHome, viewPrepaHome2 } = this.state
    let { isLogged } = this.props

    console.log('preparacion')
    console.log(preparacion)
    console.log('preparacion misCursosRepasos')
    console.log(preparacion.misCursosRepasos)
    return (
      <div className="CursosCej234fg" style={isLogged && isLogged == true ? ({
        position: 'absolute',
        zIndex: '1000',
        top: '0',
        right: '0'
      }) : ({})}>
        <div className="CC-headerNavLeft">

          <div className='CC-HN-top'>

            <div className="logoMenuEd" style={{ display: 'flex', padding: '0px', alignSelf: 'flex-start', cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
              <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
            </div>

            <div className='CC-HN-titulo1'> ¡Preparate con CEJ y consigue tu plaza! </div>

            <div className='CC-HN-NavPrepa'>
              {/* <div id="goNormal" onClick={this.onHandleClick} className='CC-HN-NP-link' style={preparacion.modePrepa && preparacion.modePrepa == 'normal' ? ({ paddingLeft: '0px', color: '#3469b7' }) : ({ paddingLeft: '0px' })}>
                Cursos
              </div> */}
              {/* {isLogged && isLogged == true && preparacion.misCursosRepasos.length > 0 ? (<div id="goRepaso" onClick={this.onHandleClick} className='CC-HN-NP-link' style={preparacion.modePrepa && preparacion.modePrepa == 'repaso' ? ({ borderLeft: '2px solid grey', color: '#3469b7' }) : ({ borderLeft: '2px solid #3469b7' })}>
                Repaso de mis Cursos.
              </div>) : null} */}
            </div>

          </div>
          <div className='divAtrasv'>
            <div className='CC-HN-medInfo'>
              SELECCIONE UN MODO DE PREPARACIÓN
            </div>
          </div>


          <div className='CC-HN-navBottom'>
            <div className='divAtrasv'>
              <div className='CC-HN-navBottom-atras' id="atras" onClick={this.onHandleClick}>
                Atrás
              </div>
            </div>

            <div className='CC-HN-navBottom-help' id="help" onClick={this.onHandleClick}>
              ¿Necesitas ayuda?
              <i className="fa-light fa-phone-flip"></i>
            </div>
          </div>
        </div>

        <div className="CC-headerNavLeft2">

          <div className='CC-HN-top2'>

            <div className="logoMenuEd" style={{ display: 'flex', padding: '0px', alignSelf: 'flex-start', cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
              <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
            </div>
            <div>
              <div className='CC-HN-titulo1'> ¡Preparate con CEJ y consigue tu plaza! </div>

              <div className='CC-HN-NavPrepa'>
                {/* <div className='CC-HN-NP-link' style={preparacion.modePrepa && preparacion.modePrepa == 'normal' ? ({ paddingLeft: '0px', color: '#3469b7' }) : ({ paddingLeft: '0px' })}>
                  Cursos
                </div> */}
                {/* <div className='CC-HN-NP-link' style={preparacion.modePrepa && preparacion.modePrepa == 'repaso' ? ({ paddingRight: '0px', borderLeft: '2px solid grey', color: '#3469b7' }) : ({ paddingRight: '0px', borderLeft: '2px solid #3469b7' })}>
                  Repaso de mis Cursos.
                </div> */}
              </div>
            </div>
          </div>



          <div className='CC-HN-navBottom'>
            <div className='divAtrasv'>
              <div className='CC-HN-navBottom-atras' id="atras" onClick={this.onHandleClick}>
                Atrás
              </div>
            </div>

            <div className='CC-HN-navBottom-help' id="help" onClick={this.onHandleClick}>
              ¿Necesitas ayuda?
              <i className="fa-light fa-phone-flip"></i>
            </div>
          </div>
        </div>

        <div className="CC-contentCur">
          <div className='CC-CC-bodyOfertas'>
            {preparacion && preparacion.modePrepa == 'normal' && preparacion.inicio.map((cls, key0) =>
              <div className={key0 == 1 ? ('CC-CC-bO-tarjeta controlWidTarjeta') : ('CC-CC-bO-tarjeta')}
                style={{ zIndex: preparacion.inicio.length - key0 }}
                key={key0}>
                <div className='CC-CC-bO-T-head'>
                  <div className='CC-CC-bO-T-info1'>
                    {cls.titulo1}
                  </div>
                  <div className='CC-CC-bO-T-info2'>
                    {cls.titulo2}
                  </div>
                </div>

                <div className='CC-CC-bO-T-infoText' style={

                  cls.tipoPreparacion != 'Presencial' &&
                    cls.tipoPreparacion != 'Online_Video_Presencial'
                    ? (
                      {}
                    ) : ({ maxWidth: 'unset' })

                }>

                  {ReactHtmlParser(cls.infoText)}
                </div>

                <div className='CC-CC-bO-T-boton' id={"openTipoPreparacion-" + key0} onClick={this.onHandleClick}>
                  MÁS INFORMACIÓN
                </div>

                {cls.tipoPreparacion != 'Presencial' &&
                  cls.tipoPreparacion != 'Online_Video_Presencial'
                  ? (
                    <div className='CC-CC-bO-T-precio' >
                      desde
                      <b>{cls.precioDesde}€</b>
                      mes
                    </div>
                  ) : null}

              </div>
            )}

            {preparacion && preparacion.modePrepa == 'repaso' && preparacion.misCursosRepasos.map((cls, key0) =>
              <div className={key0 == 1 ? ('CC-CC-bO-tarjeta controlWidTarjeta') : ('CC-CC-bO-tarjeta')}
                style={{ zIndex: preparacion.misCursosRepasos.length - key0 }}
                key={key0}>
                <div className='CC-CC-bO-T-head'>
                  <div className='CC-CC-bO-T-info1'>
                    {cls.titulo1}
                  </div>
                  <div className='CC-CC-bO-T-info2'>
                    {cls.titulo2}
                  </div>
                </div>

                <div className='CC-CC-bO-T-infoText'>

                  {ReactHtmlParser(cls.infoText)}
                </div>

                <div className='CC-CC-bO-T-boton' id={"openTipoRepaso-" + key0} onClick={this.onHandleClick}>
                  MÁS INFORMACIÓN
                </div>

                <div className='CC-CC-bO-T-precio' >
                  desde
                  <b>{cls.precioDesde}€</b>
                </div>

              </div>
            )}




          </div>

          <div className='CC-CC-bodyOfertas2'>
            {preparacion && preparacion.modePrepa == 'normal' ? (
              <div className={'CC-CC-bO-tarjeta'} >
                <div className='CC-CC-bO-T-head'>
                  <div className='CC-CC-bO-T-info1'>
                    {preparacion.inicio[viewPrepaHome].titulo1}
                  </div>
                  <div className='CC-CC-bO-T-info2'>
                    {preparacion.inicio[viewPrepaHome].titulo2}
                  </div>
                </div>

                <div className='CC-CC-bO-T-infoText' style={

                  preparacion.inicio[viewPrepaHome].tipoPreparacion != 'Presencial' &&
                    preparacion.inicio[viewPrepaHome].tipoPreparacion != 'Online_Video_Presencial'
                    ? (
                      {}
                    ) : ({ maxWidth: 'unset' })

                }>

                  {ReactHtmlParser(preparacion.inicio[viewPrepaHome].infoText)}
                </div>

                <div className='CC-CC-bO-T-boton' id={"openTipoPreparacion-" + viewPrepaHome} onClick={this.onHandleClick}>
                  MÁS INFORMACIÓN
                </div>

                {

                  preparacion.inicio[viewPrepaHome].tipoPreparacion != 'Presencial' &&
                    preparacion.inicio[viewPrepaHome].tipoPreparacion != 'Online_Video_Presencial'
                    ? (
                      <div className='CC-CC-bO-T-precio'>
                        desde
                        <b>{preparacion.inicio[viewPrepaHome].precioDesde}€</b>
                      </div>
                    ) : null

                }

                {parseInt(viewPrepaHome) > 0 ? (
                  <i className="fa-light fa-chevron-left bLftNavSlid" id="left" onClick={this.onHandleClick}></i>
                ) : null}

                {parseInt(viewPrepaHome) < preparacion.inicio.length - 1 ? (
                  <i className="fa-light fa-chevron-right brgtNavSlid" id="right" onClick={this.onHandleClick}></i>
                ) : null}

              </div>
            ) : null}

            {preparacion && preparacion.modePrepa == 'repaso' ? (
              <div className={'CC-CC-bO-tarjeta'} >
                <div className='CC-CC-bO-T-head'>
                  <div className='CC-CC-bO-T-info1'>
                    {preparacion.misCursosRepasos[viewPrepaHome2].titulo1}
                  </div>
                  <div className='CC-CC-bO-T-info2'>
                    {preparacion.misCursosRepasos[viewPrepaHome2].titulo2}
                  </div>
                </div>

                <div className='CC-CC-bO-T-infoText'>

                  {ReactHtmlParser(preparacion.misCursosRepasos[viewPrepaHome2].infoText)}
                </div>

                <div className='CC-CC-bO-T-boton' id={"openTipoRepaso-" + viewPrepaHome2} onClick={this.onHandleClick}>
                  MÁS INFORMACIÓN
                </div>

                <div className='CC-CC-bO-T-precio'>
                  desde
                  <b>{preparacion.misCursosRepasos[viewPrepaHome2].precioDesde}€</b>
                </div>

                {parseInt(viewPrepaHome2) > 0 ? (
                  <i className="fa-light fa-chevron-left bLftNavSlid" id="left2" onClick={this.onHandleClick}></i>
                ) : null}

                {parseInt(viewPrepaHome2) < preparacion.misCursosRepasos.length - 1 ? (
                  <i className="fa-light fa-chevron-right brgtNavSlid" id="right2" onClick={this.onHandleClick}></i>
                ) : null}

              </div>
            ) : null}

          </div>
        </div>

        <div className="CC-headerNavRight">

        </div>

        {/* <div className="bottomLogin">
            <Footer />
        </div> */}
      </div>
    );

  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    iniciarSesion
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PreparacionCursos);

